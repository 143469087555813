/* eslint-disable no-unused-vars */
import { Space, Table,Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import React, { useState,useEffect,useRef} from 'react';
import ApprosActions from './ApprosActions';
import StatusActions from './statusActions';
import reappro from "../../services/reappro";
import moment from "moment"
import DatePicker from "react-datepicker";
import Highlighter from 'react-highlight-words';

const { RangePicker } = DatePicker;

const ListAppros = ({state}) => {
  let locale = {
    emptyText: " Pas de réapprovisionnement",
  };
  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [content, setContent] = useState("");

  useEffect(() => {
    setLoggedIn(false)
    reappro.getAllReap().then(
      (response) => {
        setContent(response.data.response.reapprovisionnement);
        setLoggedIn(true)
      },
     
    );
  }, []);
   
 
  const handleChange = (pagination, filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);
     setFilteredInfo(filters);
     setSortedInfo(sorter);
   };
   const [dates, setDates] = useState(null);
   const [value, setValue] = useState(null);
 
  //  function convert(str) {
  //    var date = new Date(str),
  //      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //      day = ("0" + date.getDate()).slice(-2);
  //    return [date.getFullYear(), mnth, day].join("-");
  //  }
 
 
  //  function convertreturnValue2() {
  //    if(value !== null){
  //    var date = new Date(value[1]._d),
  //      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //      day = ("0" + date.getDate()).slice(-2);
  //    return [date.getFullYear(), mnth, day].join("-");
  //    }
  //  }
   const onOpenChange = (open) => {
     if (open) {
       setDates([null, null]);
     } else {
       setDates(null);
     }
     if(value !== null){
       
     }
     
   };
   const [searchText, setSearchText] = useState('');
   const [searchedColumn, setSearchedColumn] = useState('');
   const searchInput = useRef(null);
   const handleSearch = (selectedKeys, confirm, dataIndex) => {
     confirm();
     setSearchText(selectedKeys[0]);
     setSearchedColumn(dataIndex);
   };
   const handleReset = (clearFilters) => {
     clearFilters();
     setSearchText('');
   };
   const getColumnSearchProps = (dataIndex) => ({
     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
       <div
         style={{
           padding: 8,
         }}
         onKeyDown={(e) => e.stopPropagation()}
       >
         <Input
           ref={searchInput}
           placeholder={`Chercher ${dataIndex}`}
           value={selectedKeys[0]}
           onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
           onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
           style={{
             marginBottom: 8,
             display: 'block',
           }}
         />
         <Space>
           <Button
             onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
             // icon={<SearchOutlined />}
             type="link"
             size="small"
             style={{
               width: 90,
             }}
           >
             Chercher
           </Button>
           <Button
             onClick={() => clearFilters && handleReset(clearFilters)}
             type="link"
             size="small"
             style={{
               width: 90,
             }}
           >
             Réinitialiser
           </Button>
           <Button
             type="link"
             size="small"
             onClick={() => {
               confirm({
                 closeDropdown: false,
               });
               setSearchText(selectedKeys[0]);
               setSearchedColumn(dataIndex);
             }}
           >
             Filtrer
           </Button>
           <Button
             type="link"
             size="small"
             onClick={() => {
               close();
             }}
           >
             Fermer
           </Button>
         </Space>
       </div>
     ),
     filterIcon: (filtered) => (
       <SearchOutlined
         style={{
           color: filtered ? '#1890FF' : undefined,
         }}
       />
     ),
     onFilter: (value, record) =>
       record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
     onFilterDropdownOpenChange: (visible) => {
       if (visible) {
         setTimeout(() => searchInput.current?.select(), 100);
       }
     },
     render: (text) =>
       searchedColumn === dataIndex ? (
         <Highlighter
           highlightStyle={{
             backgroundColor: '#FFC069',
             padding: 0,
           }}
           searchWords={[searchText]}
           autoEscape
           textToHighlight={text ? text.toString() : ''}
         />
       ) : (
         text
       ),
   });
   const columns = [
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
     // ...getColumnSearchProps('user'), 
      // sorter: (a, b) => a.nom.length - b.nom.length,
      // sortOrder: sortedInfo.columnKey === 'nom' ? sortedInfo.order : null,
      render: (text, record) => {
        return record?.user
         },
    },
    {
        title: 'Montant',
        dataIndex: 'amount',
        key: 'amount',
        // sorter: (a, b) => a.amount.length - b.amount.length,
        // sortOrder: sortedInfo.columnKey === 'amount' ? sortedInfo.order : null,
        ellipsis: true,
        render: (text, record) => {
          return record?.amount
           },
    },
    
    {
      title: 'Date',
      dataIndex: 'transactions',
      key: 'date',
    
      render: (text, record) => {
        return moment(record?.createdAt).format("DD-MM-YYYY  H:mm:ss");

         },
      //   filters :[
      //   {
      //     text:<RangePicker
      //             value={dates || value}
      //             disabledDate={disabledDate}
      //             onCalendarChange={(val) => setDates(val)}
      //             onChange={(val) => setValue(val)}
      //             onOpenChange={onOpenChange}
      //          />,
      //         value: convertreturnValue1()
      //   }
      // ],
      // filteredValue: filteredInfo.date || null,
      // onFilter: (value, record) => record.date.includes(value),
      // sorter: (a, b) => a.date.length - b.date.length,
      // sortOrder: sortedInfo.columnKey === 'date' ? sortedInfo.order : null,
      // ellipsis: true,
    },
    {
      title: 'Etat',
      dataIndex: 'statut',
      key: 'statut',
      filters: [
        {
          text: 'Pending',
          value: 'Pending',
        }, 
          {
            text: 'Failed',
            value: 'Failed',
          },
        {
          text: 'Successs',
          value: 'Success',
        },
      ],
      filteredValue: filteredInfo.address || null,
      onFilter: (value, record) => record.address.includes(value),
      // sorter: (a, b) => a.address.length - b.address.length,
      // sortOrder: sortedInfo.columnKey === 'address' ? sortedInfo.order : null,
      ellipsis: true,
      render: (text, record) => {
        return <StatusActions record = {record}/>
         },
    },
    
    {
      title: 'Actions ',
      key: 'action',
      dataIndex: 'sup_approvement',
      width:'13%',
      render: (reclamations_approvement, record) => {
          return <ApprosActions record={record} />
      }
  },
  ];
  return (
    <>
      <Space
        style={{
          marginBottom: 16,
        }}
      >
      </Space>
      <Table locale={locale} columns={columns} pagination={{ pageSize: 5}} dataSource={content} loading ={!isLoggedIn} onChange={handleChange} />
    </>
  );
};
export default ListAppros;
import React from "react";
const variantClasses = {
  h1: "font-semibold lg:text-[4px] 2xl:text-[5px] xl:text-[5px] text-[6px]",
  h2: "font-bold lg:text-[32px] xl:text-[40px] 2xl:text-[45px] text-[48px] 3xl:text-[54px]",
  h3: "font-bold lg:text-[27px] xl:text-[33px] 2xl:text-[38px] text-[40px] 3xl:text-[45px]",
  h4: "lg:text-[21px] xl:text-[27px] 2xl:text-[30px] text-[32px] 3xl:text-[36px]",
  h5: "lg:text-[16px] xl:text-[20px] 2xl:text-[22px] text-[24px] 3xl:text-[27px]",
  h6: "font-semibold lg:text-[13px] xl:text-[16px] 2xl:text-[19px] text-[20px] 3xl:text-[22px]",
  body1:
    "lg:text-[10px] xl:text-[13px] 2xl:text-[15px] text-[16px] 3xl:text-[18px]",
  body2:
    "xl:text-[11px] 2xl:text-[13px] text-[14px] 3xl:text-[16px] lg:text-[9px]",
};
const Text = ({ children, className, variant, as, ...restProps }) => {
  const Component = as || "span";
  return (
    <Component
      className={`${className} ${variantClasses[variant]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };

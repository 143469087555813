import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Listemasters from "pages/Listemasters";
import AjoutsuperviseurCom from "pages/AjoutSupCommercial";
import Ajoutsuperviseur from "pages/AjoutSuperviseurClient";
import AjoutMaster from "pages/AjoutMaster";
import AjoutCaissier from "pages/AjoutCaissier";
import ModifierMaster from "pages/ModifierMaster";
import ModifierCaissier from "pages/ModifierCaissier";
import ModifierSuperviseur from "pages/ModifierSuperviseurClient";
import ModifierSuperviseurCom from "pages/ModifierSupCommercial";
import Listesuperviseursmaster from "pages/ListesuperviseursClient";
import Listesuperviseurscom from "pages/ListeSupCommerciaux";
import Listecaissiersmaster from "pages/Listecaissiersmaster";
import Effectifmaster from "pages/Effectifmaster";
import Dashboardmaster from "pages/Dashboardmaster";
import ReapproSup from "pages/ReapproSup";
import Reclamations from "pages/Reclamations";
import Transactions from "pages/Transactions";
import Login from "pages/Login";
import ProfilePage from "pages/Profile";
import ListeUserPage from "pages/ListeUsers";

const ProjectRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboardmaster" element={<Dashboardmaster />} />
        <Route path="/effectifmaster" element={<Effectifmaster />} />
        <Route path="/listesupclient" element={<Listesuperviseursmaster />} />
        <Route path="/ajoutsuperviseur" element={<Ajoutsuperviseur />} />
        <Route path="/listemasters" element={<Listemasters />} />
        <Route path="/ajoutcaissier" element={<AjoutCaissier />} />
        <Route path="/ajoutsupcom" element={<AjoutsuperviseurCom />} />
        <Route path="/ajoutmaster" element={<AjoutMaster/>} />
        <Route path="/modifiercaissier" element={<ModifierCaissier />} /> 
        <Route path="/modifiersupclient" element={<ModifierSuperviseur />} /> 
        <Route path="/modifiersupcom" element={<ModifierSuperviseurCom />} /> 
        <Route path ="/modifiermaster" element={<ModifierMaster/>}/>
        <Route path="/listecaissiersmaster" element={<Listecaissiersmaster />} />
        <Route path="/listesupcom" element={<Listesuperviseurscom />} />
        <Route path="/reapprosup" element={<ReapproSup />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/reclamations" element={<Reclamations />} />
        <Route path="/login" element={<Login/>} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/listeusers" element={<ListeUserPage />} />
      </Routes>
    </Router>
  );
};

export default ProjectRoutes;

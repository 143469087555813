import axios from "axios";
import authHeader from "./auth-header";

const updateProfile = (userId,firstName,lastName,phone,email) => {
  const data = JSON.stringify({
    "userId": userId,
    "firstName": firstName,
    "lastName": lastName,
    "phone": phone,
    "email": email,
  });
  const config = {
    method: 'put',
    url: `${process.env.REACT_APP_API_URL}/updateProfile`,
    headers: authHeader(),
    data : data
  };
  return axios(config)
};

const getBalance = (userId) => {
  const data = JSON.stringify({
    "userId": userId,
  });
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/balance`,
    headers: authHeader(),
    data : data
  };
  return axios(config)
};

const getAllTransactions = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  const data = JSON.stringify({
    "userId": userId,
  });
  
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/transactions`, 
    headers: authHeader(),
    data : data
  };
  return axios(config)
};

const getAllReclamations = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  const data = JSON.stringify({
    "userId": userId,
  });
  const config = {
    method:'get',
    url: `${process.env.REACT_APP_API_URL}/reclamations`,
    headers:authHeader(),
    data : data
  
  };
  return axios(config)
}

const generateOtp  = (userId)=>{  
  const data = {
    "userId": userId,
  };
    const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/generateOtp`,
    headers: authHeader(),
    data : data
  };
  return axios(config)
}

const verifyPhoneOtp = (userId,otp)=>{

  var data = JSON.stringify({
    "userId": userId,
    "otp": otp
  });
  
  var config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/verify`,
    headers:authHeader(),
    data : data
  };
  
   return axios(config)

}

const getAllMasters = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const id = response.response.user.id
  const data = JSON.stringify({
    "id":id
  });  
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/masters`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const getAllSupCom = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const id = response.response.user.id
  const data = JSON.stringify({
    "id":id
  });  
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/supcommerciaux`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const getAllSupClients = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const id = response.response.user.id
  const data = JSON.stringify({
    "id":id
  });  
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/supclients`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const getAllCaissiers = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const id = response.response.user.id
  const data = JSON.stringify({
    "id":id
  });  
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/caissiers`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const getUsers = (id) => {
  
  const data = JSON.stringify({
  "id":id
}); 
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/user`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const nbTrx = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  const data = JSON.stringify({
    "id": userId,
  });
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/count`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const nbReclamations = () => {
  // const response = JSON.parse(localStorage.getItem('user'));
  // const userId = response.response.user.id
  // const data = JSON.stringify({
  //   "userId": userId,
  // });
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/countReclamations`,
    headers: authHeader(),
    //data: data
} 
  return axios(config) 
}

const nbCaissiers = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const id = response.response.user.id
  const data = JSON.stringify({
    "userId": id,
  });
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/countCaissiers`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const nbSupComm = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const id = response.response.user.id
  const data = JSON.stringify({
    "userId": id,
  });
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/countSupComm`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const nbSupClients = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const id = response.response.user.id
  const data = JSON.stringify({
    "userId": id,
  });
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/countSupClients`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const nbSupMasters = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const id = response.response.user.id
  const data = JSON.stringify({
    "userId": id,
  });
  const config = {
    method:'post',
    url: `${process.env.REACT_APP_API_URL}/countMasters`,
    headers: authHeader(),
    data: data
} 
  return axios(config) 
}

const updatePwd = (userId,password,newPassword) => {
  const data = JSON.stringify({
    "userId": userId,
    "password": password,
    "newPassword": newPassword
    
  });
  const config = {
    method: 'put',
    url: `${process.env.REACT_APP_API_URL}/updatepwd`,
    headers: authHeader(),
    data : data
  };
  return axios(config)
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  updatePwd,
    updateProfile,
    getBalance,
    getAllTransactions,
    getAllReclamations,
    generateOtp,
    getUsers,
    getAllMasters,
    getAllSupClients,
    getAllCaissiers,
    getAllSupCom,
    verifyPhoneOtp,
    nbReclamations,
    nbTrx,
    nbCaissiers,
    nbSupClients,
    nbSupComm,
    nbSupMasters
  };
import React from "react";
import { Column, Img, Row } from "components";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const Sidebar = (props) => { 
  const navigate = useNavigate();
  
  function handleNavigate30() {
    navigate("/dashboardmaster");
  }

  //const activeLink = "font-montserrat font-semibold xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] text-indigo_A700 w-[auto]";
    const normalLink = "rowfile md:text-center";

   const activeLink = "text-bluegray_600 font-semibold xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px]  w-[auto]";
  // const normalLink = " common-pointer rowcar";
 
  return (
    <>
      <aside className={props.className}>
        <div className="">
          <Column className="bg-white_A700 justify-start lg:pr-[46px] xl:pr-[57px] 2xl:pr-[64px] 3xl:pr-[77px] lg:py-[46px] xl:py-[57px] 2xl:py-[64px] 3xl:py-[77px] shadow-bs w-[100%]">
            <Column className="justify-start lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] w-[100%]">
              <Img
               src="images/coolupay1.png"
                className="lg:h-[70px] xl:h-[38px] 2xl:h-[42px] 3xl:h-[51px] w-[200px]"
                alt="logo coolupay"  
                onClick={handleNavigate30}
              />
              <Row className="items-start 2xl:mt-[108px] 3xl:mt-[130px] lg:mt-[77px] xl:mt-[96px] w-[100%]">
                <Img
                  src="images/img_mobile_24X24.svg"
                  className="file"
                  alt="mobile"
                />
              <NavLink
                to = '/dashboardmaster'
                className={({ isActive }) =>
                isActive ? activeLink :normalLink
              }
              >
                  Dashboard
                  </NavLink>
              </Row>
            </Column>
            <Row className="items-center lg:mt-[22px] xl:mt-[27px] 2xl:mt-[31px] 3xl:mt-[37px] w-[71%]">
              {/* <Line className="bg-indigo_A700 lg:h-[42px] xl:h-[53px] 2xl:h-[60px] 3xl:h-[71px] rounded-bl-[0] rounded-br-[8px] rounded-tl-[0] rounded-tr-[8px] w-[6px]" /> */}
              <Img
                src="images/img_camera_44X44.svg"
                className="lg:ml-[23px] xl:ml-[28px] 2xl:ml-[32px] 3xl:ml-[38px] file"
                alt="ticket"
              /> <NavLink
                to = '/effectifmaster'
                className={({ isActive }) =>
                isActive ? activeLink :normalLink
              }
              >
                Effectif
              </NavLink>
            </Row>
           
            <Column className="justify-start lg:mb-[244px] xl:mb-[305px] 2xl:mb-[344px] 3xl:mb-[412px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[21px] xl:mt-[27px] 2xl:mt-[30px] 3xl:mt-[36px] w-[78%]">
              <Row className="items-start w-[73%]">
                <Img
                  src="images/img_umoneywithdra.svg"
                  className="file"
                  alt="umoneywithdra"
                /> 
                <NavLink
                to = '/reapprosup'
                className={({ isActive }) =>
                isActive ? activeLink :normalLink
              }
              >
                  Réapprovisionnements  
                  
                </NavLink>
              </Row>
              <Row className="items-start lg:mt-[35px] xl:mt-[44px] 2xl:mt-[50px] 3xl:mt-[60px] w-[95%]">
                <Img
                  src="images/img_uexchange_24X24.svg"
                  className="file"
                  alt="uexchange"
                />
                 <NavLink
                to = '/transactions'
                className={({ isActive }) =>
                isActive ? activeLink :normalLink
              }
              >
                  Transactions
                </NavLink>
              </Row>
              <Row className="items-start lg:mt-[34px] xl:mt-[43px] 2xl:mt-[48px] 3xl:mt-[58px] w-[100%]">
                <Img
                  src="images/img_warning_24X24.svg"
                  className="file"
                  alt="warning"
                />
                <NavLink
                to = '/reclamations'
                className={({ isActive }) =>
                isActive ? activeLink :normalLink
              }
              >
                  Réclamations</NavLink>
              </Row>
            </Column>
          </Column>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;

import { Button } from 'components/Button';
import React, { useState } from 'react';
import { Alert} from 'antd';

export default function StatusActions(props) {
    const status = props?.record?.status
    const [loading] = useState(false)
    console.log(status)
    if (loading) return <Button type="primary" shape="circle" loading />
    if (status === 'INITIATED') return (
        <Alert style={{border:0}} message="EN COURS" type="warning" showIcon />
    )
    if (status === 'FAILED') return (
        <Alert style={{border:0}} message="ÉCHOUÉE" type="error" showIcon />
    )
    if (status === 'COMPLETED') return (
         <Alert style={{border:0}} message="RÉUSSIE" type="success" showIcon />
    )
    if (status === '') return (
        <Alert style={{border:0}} message="ÉCHOUÉE" type="error" showIcon />
   )
    return (
        <Alert style={{border:0}} message="ÉCHOUÉE" type="error" showIcon />
    )
}
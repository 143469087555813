/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import authHeader from "./auth-header";

const updateUser = (id,firstName,lastName,username,phone,cni,email,adresse,created_by) => {
  const data = JSON.stringify({
     "id": id,
    "firstName": firstName,
    "lastName": lastName,
    "username": username,
    "phone": phone,
    "CNI": cni,
    "email": email,
    "adresse": adresse,
    "userId":created_by
  });
  const config = {
    method: 'put',
    url: `${process.env.REACT_APP_API_URL}/user`,
    headers: authHeader(),
    data : data
  };
  return axios(config)
};
export default {
    updateUser
}
import axios from "axios";
import authHeader from "./auth-header";

//block user
const deblock = (id,userId) => {
  const data = JSON.stringify({
    "id" : id,
    "userId": userId
  });
  const config = {
    method: 'put',
    url: `${process.env.REACT_APP_API_URL}/deblock` ,
    headers: authHeader(),
    data : data
  };
  return axios(config)
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    deblock
}

import axios from "axios";
import authHeader from "./auth-header";

//update solde
const updateappel = (id,montant,user,status,senderId,receiverId,comment) => {
  const data = JSON.stringify({
    "id": id,
    "amount": montant,
    "comment": comment,
    "user": user,
    "status": status,
    "senderId": senderId,
    "receiverId":receiverId
  });
  const config = {
    method: 'put',
    url: `${process.env.REACT_APP_API_URL}/appel`,
    headers: authHeader(),
    data : data
  };
  return axios(config)
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  updateappel
}

import {Space} from 'antd';
import { Button } from 'components/Button';
import React, { useState } from 'react';
import { Alert} from 'antd';



export default function StatusActions(props) {

    const status = props?.record?.status
    const [loading] = useState(false)
    console.log(status)
   
    if (loading) return <Button type="primary" shape="circle" loading />
    if (status === 'PENDING') return (
       // <Button variant="FillAmberA40059"> INITIATED </Button>
        <Alert style={{border:0}} message="EN COURS" type="warning" showIcon />
       
       
    )

    if (status === 'FAILED') return (
        // <Button variant="FillRedA70059"> FAILED </Button>
        <Alert style={{border:0}} message="REJETÉE" type="error" showIcon />
    )
    if (status === 'SUCCESS') return (
        // <Button variant="FillLightgreen60059"> COMPLETED </Button>
         <Alert style={{border:0}} message="TRAITÉE" type="success" showIcon />
    )
    return (
        <Space size='middle' >
            
        </Space>
    )

}
import axios from "axios";
import authHeader from "./auth-header";

//update solde
const updatesolde = (receiverId,senderId,solde) => {
  const data = JSON.stringify({
    "receiverId": receiverId,
    "senderId" : senderId ,
    "solde": solde
  });
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/solde`,
    headers: authHeader(),
    data : data
  };
  return axios(config)
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  updatesolde
}
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState,useEffect} from "react";
import { Column, Row, Img, Text, Button, List, Stack, Line } from "components";
import { useNavigate, Navigate } from "react-router-dom";
import Dropdown from "components/Dropdown";
import Sidebar from "components/Sidebar/Sidebar";
import user from "../../services/user"
import { useSelector } from "react-redux";
import AppelsMaster from "components/Dashboard";
import { EyeInvisibleTwoTone,EyeTwoTone } from '@ant-design/icons';

const DashboardmasterPage = () => {
  const navigate = useNavigate();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { isLoggedIn } = useSelector(state => state.auth);
  const [balance, setBalance] = useState("");
  const [trx, setTrx] = useState("");
  const [supCom, setSupCom] = useState("");
  const [reclamations, setReclamations] = useState(""); 
  const [showBalanceIcon, setShowBalanceIcon] = useState(false);
  let calssSoled = true;
      
  const _classNameSolde = "text-bluegray_600 font-montserrat font-bold lg:text-[21px] xl:text-[20px] 2xl:text-[30px] 3xl:text-[36px]";

  useEffect(() => {
        
    if (currentUser) {
    user.getBalance(currentUser.response.user.id).then(
      (response) => {
        setBalance(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
          setBalance(_content);
      }
    );
  }
  
  }, []);

  useEffect(() => {

    if (currentUser) {
    user.nbTrx(currentUser.response.user.id).then(
      (response) => {
        //console.log('trx :' , response.data )
        setTrx(response.data.count);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
          setTrx(_content);
      }
    );
     user.nbReclamations().then(
      (response) => {
        setReclamations(response.data.count);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
          setReclamations(_content);
      }
    );
    user.nbSupComm(currentUser.response.user.id).then(
      (response) => {
        //console.log('nbsup :', response.data)
        setSupCom(response.data.count);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
          setSupCom(_content);
      }
    );
    }
  }, []);

  function handleBalanceIconClick() {
    setShowBalanceIcon(!showBalanceIcon);
  }
  function handleNavigate1() {
    navigate("/listesupcom");
  }
  function handleNavigate8() {
    navigate("/transactions");
  }
  function handleNavigate9() {
    navigate("/reclamations");
  }
 
  if(100000 >= parseInt(balance.solde) ){
    calssSoled = false
  }
  if (!isLoggedIn) {
    return <Navigate replace to="/login" />;
  } else { 
     return (
    <>
      <Column className="bg-bluegray_50 font-montserrat items-center justify-start mx-[auto] w-[100%]">
        <Row className="justify-between w-[98%]">
        <Sidebar className="w-[20%]" />
          <Column className="bg-white_A700 items-center lg:py-[5px] xl:py-[6px] 2xl:py-[7px] 3xl:py-[9px] rounded-bl-[0] rounded-br-[0] rounded-tl-[12px] rounded-tr-[12px] shadow-bs2 w-[63%]">
          
          <Row className="items-start w-[92%]">
                <Button
                  className="flex lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px] items-center justify-center mt-[1px] rounded-radius50 lg:w-[21px] xl:w-[27px] 2xl:w-[30px] 3xl:w-[36px]"
                  size="smIcn"
                  variant="icbFillGray201"
                >
                  <Img
                    src="images/img_arrowleft.svg"
                    className="flex items-center justify-center"
                    alt="arrowleft"
                  />
                </Button>
                <Text className="Dashboard" as="h5" variant="h5">
                  Dashboard
                </Text>
                {showBalanceIcon?
             (<Row className="items-center justify-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[36%]">
             <Text className="text-black_900 p-2 w-[auto]" as="h1" variant="h1">
             <span className="text-gray_601 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                      Solde{" "}
                    </span>
               <span  className={ calssSoled ? "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber_A700 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
               ******** Fcfa
                 </span>
             </Text>
             
             <br/>
            <button onClick={handleBalanceIconClick}> <EyeTwoTone twoToneColor="#674788" size="60px" title=" Afficher Solde"/> </button>
           </Row>)
             :(
              <Row className="items-center justify-center lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[36%]">
              <Text className="text-black_900 p-2 w-[auto]" as="h1" variant="h1">
                <span className="text-gray_600 font-montserrat font-medium lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px]">
                  Solde{" "}
                </span>
                <span  className={ calssSoled ? "text-bluegray_600 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" : "text-amber_A700 font-montserrat font-bold lg:text-[14px] xl:text-[20px] 2xl:text-[20px] 3xl:text-[36px]" } >
                   {new Intl.NumberFormat('de-DE').format(balance.solde)} Fcfa
                  </span>
              </Text>
              <button  onClick={handleBalanceIconClick}> <EyeInvisibleTwoTone  twoToneColor="#674788" size="50px" title=" Masquer Solde"/> </button>
          
            </Row>
             )}
              </Row>
          
              <Line className="bg-black_900_19 h-[1px] lg:mt-[26px] xl:mt-[33px] 2xl:mt-[37px] 3xl:mt-[44px] w-[100%]" />
            <Row className="font-montserrat items-center justify-between lg:mt-[20px] xl:mt-[26px] 2xl:mt-[29px] 3xl:mt-[35px] w-[100%]">
              <Column className="bg-white_A700 lg:p-[12px] xl:p-[16px] 2xl:p-[18px] 3xl:p-[21px] rounded-radius12 shadow-bs1 w-[26%]">
                <Column className="justify-start xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] mt-[4px] w-[61%]">
                  <Img
                    src="images/img_camera.svg"
                    className="camera"
                    alt="camera"
                  />
                  <Row className="items-start justify-between lg:mt-[18px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[100%]">
                    <Text
                      className="text-indigo_A700 w-[auto]"
                      as="h2"
                      variant="h2"
                      onClick={handleNavigate1}
                    >
                       <span  className={_classNameSolde} >
                     {new Intl.NumberFormat('de-DE').format(supCom)} 
                  </span>
                    </Text>
                    <Text
                      className="font-semibold lg:mt-[15px] xl:mt-[19px] 2xl:mt-[21px] 3xl:mt-[26px] text-black_900_cc w-[auto]"
                      variant="body1"
                      onClick={handleNavigate1}
                    >
                      Superviseurs
                    </Text>
                  </Row>
                </Column>
              </Column>
              <Column className="bg-white_A700 lg:p-[12px] xl:p-[16px] 2xl:p-[18px] 3xl:p-[21px] rounded-radius12 shadow-bs1 w-[34%]">
                <Column className="justify-start xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] mt-[4px] w-[74%]">
                  <Img
                    src="images/img_uexchange.svg"
                    className="camera"
                    alt="uexchange"
                  />
                  <Row className="items-start lg:mt-[18px] xl:mt-[22px] 2xl:mt-[25px] 3xl:mt-[30px] w-[100%]">
                    <Text
                      className="text-indigo_A700 w-[auto]"
                      as="h2"
                      variant="h2"
                      onClick={handleNavigate8}
                    >
                      <span  className={_classNameSolde} >
                     {new Intl.NumberFormat('de-DE').format(trx)} 
                  </span>
                    </Text>
                    <Text
                      className="font-semibold lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] lg:mt-[14px] xl:mt-[18px] 2xl:mt-[20px] 3xl:mt-[25px] text-black_900_cc w-[auto]"
                      variant="body1"
                      onClick={handleNavigate8}
                    >
                      Transactions
                    </Text>
                  </Row>
                </Column>
              </Column>
              <Column className="bg-white_A700 lg:p-[12px] xl:p-[15px] 2xl:p-[17px] 3xl:p-[20px] rounded-radius12 shadow-bs1 w-[35%]">
                <Column className="justify-start xl:ml-[11px] 2xl:ml-[12px] 3xl:ml-[14px] lg:ml-[8px] lg:mt-[3px] 2xl:mt-[4px] xl:mt-[4px] 3xl:mt-[5px] w-[64%]">
                  <Img
                    src="images/img_warning.svg"
                    className="camera"
                    alt="warning"
                  />
                  <Row className="items-start lg:mt-[18px] xl:mt-[23px] 2xl:mt-[26px] 3xl:mt-[32px] w-[100%]">
                    <Text
                      className="text-indigo_A700 w-[auto]"
                      as="h2"
                      variant="h2"
                      onClick={handleNavigate9}
                    >
                       <span  className={_classNameSolde} >
                     {new Intl.NumberFormat('de-DE').format(reclamations)} 
                  </span>
                    </Text>
                    <Text
                      className="font-semibold lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] lg:mt-[15px] xl:mt-[19px] 2xl:mt-[21px] 3xl:mt-[26px] text-black_900_cc w-[auto]"
                      variant="body1"
                      onClick={handleNavigate9}
                    >
                      Réclamations
                    </Text>
                  </Row>
                </Column>
              </Column>
            </Row>
            <Column className="bg-white_A700 font-montserrat items-center justify-end lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius12 shadow-bs1 w-[100%]">
              <Column className="justify-start lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[98%]">
                <Text
                   className="text-bluegray_600 font-montserrat font-bold lg:text-[21px] xl:text-[20px] 2xl:text-[30px] 3xl:text-[36px] w-[100%]"
                    as="h2"
                    variant="h2"
                    
                  >
                  Demandes d'appel de fonds récentes
                </Text>
                <Row>
                  <AppelsMaster state ={currentUser ? (currentUser.response):null} />
                 </Row>
              </Column>
            </Column>
          </Column>
          <Column className="items-center w-[15%]">
            <Row className="bg-white_A700 items-center lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius16 w-[100%]">
              <Dropdown />
              <Text className="rowsettings" variant="body1">
              <strong>{currentUser ? (currentUser.response.user.username):null}</strong>
              </Text>
              <Img
                src="images/img_ellipse1.png"
                className="lg:h-[26px] xl:h-[33px] 2xl:h-[37px] 3xl:h-[44px] lg:ml-[10px] xl:ml-[13px] 2xl:ml-[15px] 3xl:ml-[18px] rounded-radius50 lg:w-[25px] xl:w-[32px] 2xl:w-[36px] 3xl:w-[43px]"
                alt="EllipseOne"
              />
            </Row>
            <List
              className="gap-[0] min-h-[auto] xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[100%]"
              orientation="vertical"
            >
              <Column className="listfluxdetransac">
                <Column className="justify-start mt-[2px] w-[94%]">
                  <Text
                    className="font-medium text-black_900 w-[auto]"
                    variant="body2"
                  >
                    Flux de transactions
                  </Text>
                  <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                    <Img
                      src="images/img_vector2.png"
                      className="VectorTwo"
                      alt="VectorTwo"
                    />
                    <Img
                      src="images/img_vector1.svg"
                      className="VectorOne"
                      alt="VectorOne"
                    />
                  </Stack>
                  <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      lun
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mar
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mer
                    </Text>
                    <Text
                      className="mt-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      jeu
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      ven
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      sam
                    </Text>
                    <Text
                      className="text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      dim
                    </Text>
                  </Row>
                </Column>
              </Column>
              <Column className="listfluxdetransac">
                <Column className="justify-start mt-[2px] w-[94%]">
                  <Text
                    className="font-medium text-black_900 w-[auto]"
                    variant="body2"
                  >
                    Flux de reclamations
                  </Text>
                  <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                    <Img
                      src="images/img_vector2.png"
                      className="VectorThree"
                      alt="VectorThree"
                    />
                    <Img
                      src="images/img_vector1.svg"
                      className="VectorOne"
                      alt="VectorFour"
                    />
                  </Stack>
                  <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      lun
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mar
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      mer
                    </Text>
                    <Text
                      className="mt-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      jeu
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      ven
                    </Text>
                    <Text
                      className="my-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      sam
                    </Text>
                    <Text
                      className="mb-[1px] text-bluegray_100 w-[auto]"
                      as="h1"
                      variant="h1"
                    >
                      dim
                    </Text>
                  </Row>
                </Column>
              </Column>
            </List>
            <Column className="bg-white_A700 justify-end lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius16 w-[100%]">
              <Text className="columnfluxdetransac_two" variant="body2">
                Flux de reapprovisionnement
              </Text>
              <Column className="items-center justify-start lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] mx-[auto] xl:p-[11px] 2xl:p-[12px] 3xl:p-[14px] lg:p-[8px] w-[89%]">
                <Stack className="2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] w-[85%]">
                  <div className="absolute bg-blue_A700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                  <div className="absolute bg-cyan_400 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                  <div className="absolute bg-yellow_700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                  <Column className="absolute bottom-[19%] justify-start right-[10%] w-[73%]">
                    <Row className="items-start justify-between w-[100%]">
                      <Text className="Thirty" variant="body2">
                        30%
                      </Text>
                      <Text className="Forty" variant="body2">
                        40%
                      </Text>
                    </Row>
                    <Text className="columnthirty" variant="body2">
                      30%
                    </Text>
                  </Column>
                </Stack>
              </Column>
            </Column>
            <Button
                  className="2xl:mt-[85px] 3xl:mt-[102px] flex items-center justify-center lg:mt-[60px] text-center w-[92%] xl:mt-[76px]"
                  leftIcon={
                    <Img
                      src="images/img_camera_24X24.svg"
                      className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                      alt="camera"
                    />
                  }
                  shape="RoundedBorder8"
                  size="md"
                  variant="FillIndigoA700"
                >
                  <div className="bg-transparent font-bold xl:text-[10px] 2xl:text-[11px] 3xl:text-[13px] lg:text-[8px]"
                  // onClick={handleNavigate3}
                   >
                    Réapprovisionner
                  </div>
                </Button>
            
          </Column>
        </Row>
      </Column>
    </>
  );
}
 
};

export default DashboardmasterPage;

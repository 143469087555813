/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";


const login = (username, password) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/login`, 
    {
      username,
      password,
    })
    .then((response) => {
      if (response.data.success === true) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const verifyLoginOtp = (userId,otp)=>{
  
  return axios
  .post(`${process.env.REACT_APP_API_URL}/loginOtp`,
  {
   userId,
   otp,
 })
 .then((response) => {
   if (response.data.success === true) {
     localStorage.setItem("user", JSON.stringify(response.data));
   }
   return response.data;
 });
}

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  login,
  logout,
  verifyLoginOtp
};
import {VALIDE_SUCCESS,VALIDE_FAIL,SET_MESSAGE,} from "./types";
import SoldeService from "../services/decreasesolde";

export const decreasesolde = (receiverId,senderId,solde) => (dispatch) => {
    return SoldeService.decreasesolde(receiverId,senderId,solde).then(
      (response) => {
        dispatch({
          type: VALIDE_SUCCESS,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();
        dispatch({
          type: VALIDE_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    );
  };
/* eslint-disable no-unused-vars */
import { Space, Modal, Button } from 'antd';
import React, { useState } from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from 'react-redux';
import {block} from "../../actions/blockuser";
import { deblock } from '../../actions/deblock';
import OtPInput from "otp-input-react";
import { Row,Text } from 'components';
import { verifyPhoneOtp } from "../../actions/user";
// // ici on a popup qui affiche tous les details et fichiers joints par le client
function showinfoscaissiers(record) {
    
    Modal.info({
        title: 'Details du Superviseur',
        content: (
            <p style={{ marginTop: 10 }}>
              <p>Prenom :  {record.firstName}</p>
                <p>Nom :  {record.lastName}</p>
                <p>Username :  {record.username}</p>
                <p>Adresse:  {record.adresse}</p>
                <p>Numero :  {record.phone}</p>
                <p>Email :  {record.email}</p>
                <p>CNI :  {record.cni}</p>
                <br />
                {/* <DemandeProgression step={step} /> */}
            </p>
        )
    });
}
export default function CaissierActions(props) {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [successful, setSuccessful] = useState(false);
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const  [otp, setOtp]  = React.useState ("");
    const { message } = useSelector(state => state.message);
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const state = props?.record
    const userId = currentUser.response.user.id
    const id = state.id;

    const  handleSubmit =  (e) => {
    e.preventDefault();
    setSuccessful(true); 
     dispatch(verifyPhoneOtp(userId, otp))
      .then(() => {
        setSuccessful(true);
        dispatch(block(id,userId))
       
        //window.location.reload();
      })
      .catch(() => {
        setSuccessful(false);
      });
  };
  const handleSubmit0 = (e) => {
    e.preventDefault();
    setSuccessful(true); 
     dispatch(verifyPhoneOtp(userId, otp))
      .then(() => {
        setSuccessful(true);
        dispatch(deblock(id,userId))
       
        //window.location.reload();
      })
      .catch(() => {
        setSuccessful(false);
      });
  };
    
  const navigate = useNavigate(); 
    function liste(record){
          navigate(
              '/listeusers',
              {
                state: {id:record?.id }
              }
            
            ) 
      }

    // eslint-disable-next-line no-empty-pattern
    const [ ] = useState(props?.record?.caissier_approvement?.approvement)
    const [loading, ] = useState(false)
    if (loading) return <Button type="primary" shape="circle" loading />

    const blocked = props?.record?.block
    if (blocked === true) return ( 
    <Space size="small">
    <Button icon={<InfoCircleTwoTone color="#1890ff" size="25px" title="Details Caissier" />} type="text" onClick={() => { showinfoscaissiers(props?.record) }} />
    {/* <Button onClick={generate} icon={<CheckCircleTwoTone twoToneColor="#52c41a" title="Debloquer" />} type="text"  /> 
    */}
    {/* <Button onClick={generate} icon={<TeamOutlined twoToneColor="#eb2f96" title="Caissiers" onClick={() => { liste(props?.record) }} />} type="text" /> */}
   
   <Modal  title="Verification code OTP" footer={null} open={isModalOpen} onOk={handleOk} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel}>
            {message && (
     <div className="form-group">
       <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
         {message}
       </div>
     </div>
   )}
      <Text  className="Numrodubnf" as="h7" variant="h7">
        Entrer le code de validation
      </Text> 
      <br/>
      <OtPInput
       inputStyles={{width: "35px",
                    fontWeight: "bold"}}
         value={otp}
         onChange={setOtp}
         autoFocus
         OTPLength={4}
         otpType="number" disabled={false}  />
         {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
      <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
        <Button
          className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
          shape="RoundedBorder8"
          variant="FillIndigoA700"
          onClick={handleOk}
        >
          Annuler
        </Button>
        <Button
          className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
          shape="RoundedBorder8"
          onClick={handleSubmit0}
          disabled={successful}>
          {successful && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          Confirmer
        </Button>
      </Row>
    </Modal>
    </Space> )

    else
    return (
        <Space size="small">
            <Button icon={<InfoCircleTwoTone color="#1890ff" size="25px" title="Details Master" />} type="text" onClick={() => { showinfoscaissiers(props?.record) }} />
             {/* <Button onClick={generate} icon={<StopTwoTone twoToneColor="#eb2f96" title="Supprimer" />} type="text" /> 
          */}
            {/* <Button onClick={generate} icon={<TeamOutlined twoToneColor="#52c41a" title="Caissiers" onClick={() => { liste(props?.record)}} />} type="text" /> */}
            
<Modal  title="Verification code OTP" footer={null} open={isModalOpen} onOk={handleOk} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel}>
     {message && (
     <div className="form-group">
       <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
         {message}
       </div>
     </div>
   )}
      <Text  className="Numrodubnf" as="h7" variant="h7">
        Entrer le code de validation
      </Text> <br/> 
      <OtPInput
       inputStyles={{width: "35px",
                    fontWeight: "bold"}}
         value={otp}
         onChange={setOtp}
         autoFocus
         OTPLength={4}
         otpType="number" disabled={false}  />
    
      <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
        <Button
          className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
          shape="RoundedBorder8"
          variant="FillIndigoA700"
          onClick={handleOk}
        >
          Annuler
        </Button>
        <Button
          className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
          shape="RoundedBorder8"
          onClick={handleSubmit}
          disabled={successful}>
          {successful && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          Confirmer
        </Button>
      </Row>
    </Modal>
        </Space>
    )

}

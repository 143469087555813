import { VALIDE_SUCCESS, VALIDE_FAIL, SET_MESSAGE,} from "./types";
import blockuser from "../services/blockuser";

export const block= (id,userId) => (dispatch)=>{
    return blockuser.block(id,userId).then(
      (response) => {
        dispatch({
          type: VALIDE_SUCCESS,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();
        dispatch({
          type: VALIDE_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    );
};








/* eslint-disable no-unused-vars */
import { Table } from 'antd';
import React, { useState,useEffect} from 'react';
import user from "../../services/user"
import ReclamationsActions from './ReclamationsActions';
import DatePicker from "react-datepicker";
import moment from "moment"
import ServiceActions from './ServiceActions';
import StatusActions from './statusActions';

const { RangePicker } = DatePicker;

const ListeReclamations = ({state}) => {
  let locale = {
    emptyText: " Pas de reclamations",
  };
  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [content, setContent] = useState("");
  
  useEffect(() => {
    setLoggedIn(false)
    user.getAllReclamations().then(
      (response) => {
        //console.log('ListeReclamations :', response);
        setContent(response.data.response.reclamations);
        setLoggedIn(true)
      },
      (error) => {
        // eslint-disable-next-line no-unused-vars
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  }, []);
  
  const handleChange = (pagination, filters, sorter) => {
   // console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);

  // function convert(str) {
  //   var date = new Date(str),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [date.getFullYear(), mnth, day].join("-");
  // }

  function convertreturnValue1() {
    if(value !== null){
    var date = new Date(value[0]._d),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
    }
  }

  // function convertreturnValue2() {
  //   if(value !== null){
  //   var date = new Date(value[1]._d),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [date.getFullYear(), mnth, day].join("-");
  //   }
  // }
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
    if(value !== null){
      
    }
    
  };
  const columns = [
    {
      title: 'Service',
      dataIndex: 'service',
      width: '15%',
      key: 'service',
      render: (reclamations_approvement, record) => {
        return <ServiceActions record={record} />
    }
    },
    // {
    //     title: 'Nom',
    //     dataIndex: 'name',
    //     key: 'name',
    //     sorter: (a, b) => a.name.length - b.name.length,
    //     sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
    //     ellipsis: true,
    //     render: (text, record) => {
    //       return record?.user
    //        },
    // },
    
    {
        title: 'Montant',
        dataIndex: 'amount',
        key: 'amount',
        render: (text, record) => {
          return record?.amount
           },
    }, 
    {
      title: 'Date',
      dataIndex: 'transactions',
      key: 'date',
    
      render: (text, record) => {
        return moment(record?.createdAt).format("DD-MM-YYYY  H:mm:ss");
         },
      
    },
    {
        title: 'Etat',
        dataIndex: 'status',
        width: '20%',
        key: 'status',
        render: (reclamations_approvement, record) => {
          return <StatusActions record={record} />
      }
    },
   
    {
        title: 'Actions ',
        key: 'action',
        width: '13%',
        dataIndex: 'sup_approvement',
        render: (reclamations_approvement, record) => {
            return <ReclamationsActions record={record} />
        }
    },
  ];
  return (
    <>
      <Table locale={locale} dataSource={content} columns={columns} pagination={{ pageSize: 5}} loading ={!isLoggedIn} onChange={handleChange} />
    </>
  );
};
export default ListeReclamations;
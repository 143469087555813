/* eslint-disable no-unused-vars */
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import React, { useRef,useState, useEffect } from "react";
import Highlighter from 'react-highlight-words';
import StatusActions from './statusActions';
import moment from "moment"
import ServiceActions from './serviceActions';
import user from "../../services/user";
import ButtonActions from './buttons';
//const { RangePicker } = DatePicker
 
const ListeTransactions = () => {
  let locale = {
    emptyText: " Pas de transactions",
  };
  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [content, setContent] = useState("");

  useEffect(() => {
    setLoggedIn(false)
    user.getAllTransactions().then(
      (response) => {
        setContent(response.data.response.transactions);
        setLoggedIn(true)
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
          return _content

      
      }
    );
  }, []);

  
  const handleChange = (pagination, filters, sorter) => {
   
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    
  };
  // const [dates, setDates] = useState(null);
  // const [value, setValue] = useState(null);
  
  // function convert(str) {
  //   var date = new Date(str),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [date.getFullYear(), mnth, day].join("-");
  // }

  // function convertreturnValue1() {
  //   if(value !== null){
  //   var date = new Date(value[0]._d),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [date.getFullYear(), mnth, day].join("-");
  //   }
  // }

  // function convertreturnValue2() {
  //   if(value !== null){
  //   var date = new Date(value[1]._d),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [date.getFullYear(), mnth, day].join("-");
  //   }
  // }

  // const disabledDate = (current) => {
  //   if (!dates) {
  //     return false;
  //   }
  //   const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
  //   const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
  //   return !!tooEarly || !!tooLate;
  // };
  
  // const onOpenChange = (open) => {
  //   if (open) {
  //     setDates([null, null]);
  //   } else {
  //     setDates(null);
  //   }
  //   if(value !== null){
      
  //   }
    
  // };
   
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Chercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // icon={<SearchOutlined />}
            type="link"
            size="small"
            style={{
              width: 90,
            }}
          >
            Chercher
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrer
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Fermer
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890FF' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#FFC069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  
  const columns = [
    {
      title: 'Service',
        dataIndex: 'transactions',
        key: 'service',
        width: '10%',
        render: (text, record) => {
          return <ServiceActions record={record} />
      },
      
    },
    {
        title: 'Numero',
        dataIndex: 'numero',
        key: 'numero',
        ...getColumnSearchProps('numero'),
        
        render: (text, record) => {
  
            return record?.numero
        },
    },
    {
      title: 'Montant',
      dataIndex: 'transactions',
      key: 'prix',
      render: (text, record) => {

        return record?.montant
    },
    
    },
    {
      title: 'Type',
      dataIndex: 'transactions',
      key: 'type',
      render: (text, record) => {

          return record?.type
      },
      
      
     
  },
  {
    title: 'Date',
    dataIndex: 'transactions',
    with:'15%',
    key: 'date',
  
    render: (text, record) => {

      return moment(record?.createdAt).format("DD-MM-YYYY  HH:mm:ss");
       },
  },
  {
    title: 'Status',
    dataIndex: 'transactions',
    key: 'status',
   
    render: (text, record) => {
      return <StatusActions record={record} />
  },
    
}, 
{
  title: '',
  dataIndex: 'transactions',
  key: 'Action',
  width:'8%',
  render: (text, record) => {
    return <ButtonActions record={record} />
}}

  ];
  return (
    <>
      
      <Table locale={locale} columns={columns} dataSource={content}  loading ={!isLoggedIn} pagination={{ pageSize: 4}} onChange={handleChange}  />
    </>
  );
};
export default ListeTransactions;
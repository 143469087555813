import axios from "axios";
import authHeader from "./auth-header";


const getAppels = () => {
  const response = JSON.parse(localStorage.getItem('user'));
  const userId = response.response.user.id
  const data = JSON.stringify({
    "receiverId": userId,
  });
  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/appelsReceiverId`,
    headers: authHeader(),
    data : data
  };
  return axios(config)
  };
  
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAppels,
  };
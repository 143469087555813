import React, { useState,useCallback } from "react";
import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Button,
} from "@chakra-ui/react";
import { FormControl, FormLabel, Grid, Input, Select,InputGroup,InputRightElement } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { update,updatePwd } from "../../../actions/user";
import { clearMessage } from "../../../actions/message";
//import {logout} from "../../../actions/auth"
import { Modal } from "antd";
import { useNavigate  } from 'react-router-dom';




// import AccountSettings from './AccountSettings'
//import Actions from './Actions'


const Content = () => {
  const tabs = ["Paramètres du compte", "Changer mot de passe"];

  const { user: currentUser } = useSelector((state) => state.auth);
  const [successful, setSuccessful] = useState(false);
  const [show, setShow] = React.useState(false)
  const [show1, setShow1] = React.useState(false)
  const [show2, setShow2] = React.useState(false)
  const handleClick = () => setShow(!show)
  const handleClick2 = () => setShow2(!show2)
  const handleClick1 = () => setShow1(!show1)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const userId = currentUser.response.user.id;
  //const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const user = currentUser.response.user;
  const firstname = user.firstName;
  const lastname = user.lastName;
  const Email = user.email;
  const Phone = user.phone;
  const  [password, setPassword]  = React.useState ( "" );
  const  [newpassword, setNewpassword]  = React.useState ( "" );
  const  [comfirmpassword, setComfirmpassword]  = React.useState ( "" );

  let navigate = useNavigate();
  const [firstName, setfirstName] = React.useState({
    value: firstname,

    setValue: (newValue) => {
      setfirstName({ ...firstName, value: newValue });
    },
  });
  const [lastName, setlastName] = React.useState({
    value: lastname,

    setValue: (newValue) => {
      setlastName({ ...lastName, value: newValue });
    },
  });
  const [email, setEmail] = React.useState({
    value: Email,

    setValue: (newValue) => {
      setEmail({ ...email, value: newValue });
      console.log(email.value);
    },
  });

  const [phone, setPhone] = React.useState({
    value: Phone,

    setValue: (newValue) => {
      setPhone({ ...phone, value: newValue });
    },
  });

  const onChangefirstName = (e) => {
    const firstName = e.target.value;
    setfirstName(firstName);
  };

  const onChangelastName = (e) => {
    const lastName = e.target.value;
    setlastName(lastName);
  };

  const onChangePhone = (e) => {
    const phone = e.target.value;
    setPhone(phone);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSuccessful(true);

    dispatch(
      update(
        userId,
        firstName.value || firstName,
        lastName.value || lastName,
        phone.value || phone,
        email.value || email
      )
    )
      .then(() => {
        setSuccessful(false);
        setIsModalOpen(true);

        //navigate("/om");
      })
      .catch(() => {
        setSuccessful(true);
        setIsModalOpen(true);
      });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
     if(newpassword ==="" && comfirmpassword==="" && password===""){
      return setSuccessful(false);
     }
    if(newpassword === comfirmpassword ){
              setSuccessful(true);

            dispatch(
              updatePwd(
                userId,
                password,
                newpassword
              )
            )
              .then(() => {
                setSuccessful(false);
                setIsModalOpen1(true);

                //navigate("/om");
              })
              .catch(() => {
                setSuccessful(true);
                setIsModalOpen(true);
              });

    }else{
      setSuccessful(false);
    }
  };  
  const handleOk = () => {
    setIsModalOpen(false);
    setSuccessful(false);
    dispatch(clearMessage()); 
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(clearMessage()); 
  };
  const logout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);
  const handleOk1 = () => {

    logout()
    setIsModalOpen1(false);
    setSuccessful(false);
    dispatch(clearMessage()); 
    navigate('/login')
  };

  const handleCancel1 = () => {
    setIsModalOpen(false);
    dispatch(clearMessage()); 
  };
  return (
    <>
      <Box
        as="main"
        flex={3}
        d="flex"
        flexDir="column"
        justifyContent="space-between"
        pt={5}
        bg="white"
        rounded="md"
        borderWidth={1}
        borderColor="gray.200"
        style={{ transform: "translateY(-100px)" }}
      >
        <Tabs>
          <TabList px={5}>
            {tabs.map((tab) => (
              <Tab
                key={tab}
                mx={3}
                px={0}
                py={3}
                fontWeight="semibold"
                color="brand.cadet"
                borderBottomWidth={1}
                _active={{ bg: "transparent" }}
                _selected={{ color: "brand.dark", borderColor: "brand.blue" }}
              >
                {tab}
              </Tab>
            ))}
          </TabList>

          <TabPanels px={3} mt={5}>
            <TabPanel>
              {/* <AccountSettings /> */}
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                }}
                gap={6}
              >
                <FormControl id="firstName">
                  <FormLabel>Prénom</FormLabel>
                  <Input
                    focusBorderColor="brand.blue"
                    type="text"
                    value={firstName.value}
                    onChange={onChangefirstName}
                  />
                </FormControl>
                <FormControl id="lastName">
                  <FormLabel>Nom de famille</FormLabel>
                  <Input
                    focusBorderColor="brand.blue"
                    type="text"
                    value={lastName.value}
                    onChange={onChangelastName}
                  />
                </FormControl>
                <FormControl id="phoneNumber">
                  <FormLabel>Numéro de téléphone</FormLabel>
                  <Input disabled
                    focusBorderColor="brand.blue"
                    type="tel"
                    value={phone.value}
                    onChange={onChangePhone}
                  />
                </FormControl>
                <FormControl id="emailAddress">
                  <FormLabel>Adresse e-mail</FormLabel>
                  <Input disabled
                    focusBorderColor="brand.blue"
                    type="email"
                    value={email.value}
                    onChange={onChangeEmail}
                  />
                </FormControl>
                <FormControl id="city">
                  <FormLabel>Ville</FormLabel>
                  <Select
                    focusBorderColor="brand.blue"
                    placeholder="Choisir la ville"
                  >
                    <option value="Dakar" selected>
                      Dakar
                    </option>
                  </Select>
                </FormControl>
                <FormControl id="country">
                  <FormLabel>Pays</FormLabel>
                  <Select
                    focusBorderColor="brand.blue"
                    placeholder="Choisir le pays"
                  >
                    <option value="senegal" selected>
                      Senegal
                    </option>
                  </Select>
                </FormControl>
              </Grid>
              <Box
                mt={5}
                py={5}
                px={8}
                borderTopWidth={1}
                borderColor="brand.light"
              >
                <Button onClick={handleSubmit} disabled={successful}>
                  {successful && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}{" "}
                  Mise à jour
                </Button>
              </Box>
            </TabPanel>
            <TabPanel>
            
            <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                }}
                gap={6}
              >
                <FormControl >
                  <FormLabel>Mot de passe</FormLabel>
                  <InputGroup size='md'>
                  <Input
                    pr='4.5rem'
                    type={show ? 'text' : 'password'}
                    placeholder='Entrez le mot de passe actuel'
                    value={password}
                    onChange = { e  => setPassword ( e.target.value)}
                   
                  />
                  <InputRightElement width='4.5rem'>
                    <button h='1.75rem' size='sm' onClick={handleClick}>
                      {show ? 'Masquer' : 'Afficher'}
                    </button>
                  </InputRightElement>
                </InputGroup>
                  
                </FormControl>
                <FormControl >
                  
                </FormControl>
                <FormControl >
                  <FormLabel> Nouveau mot de passe</FormLabel>
                  <InputGroup size='md'>
                  <Input
                    pr='4.5rem'
                    type={show1 ? 'text' : 'password'}
                    placeholder='Entrez le nouveau mot de passe'
                    value={newpassword}
                    onChange = { e  => setNewpassword ( e.target.value)}
                   
                  />
                  <InputRightElement width='4.5rem'>
                    <button h='1.75rem' size='sm' onClick={handleClick1}>
                      {show1 ? 'Masquer' : 'Afficher'}
                    </button>
                  </InputRightElement>
                </InputGroup>
                  
                </FormControl>
                <FormControl >
                  <FormLabel>Confirmer le nouveau mot de passe</FormLabel>
                  <InputGroup size='md'>
                  <Input
                    pr='4.5rem'
                    type={show2 ? 'text' : 'password'}
                    placeholder='Confirmer le nouveau mot de passe'
                    value={comfirmpassword}
                    onChange = { e  => setComfirmpassword ( e.target.value)}
                   
                  />
                  <InputRightElement width='4.5rem'>
                    <button h='1.75rem' size='sm' onClick={handleClick2}>
                      {show2 ? 'Masquer' : 'Afficher'}
                    </button>
                  </InputRightElement>
                </InputGroup>
                </FormControl>
                </Grid>
            <Box
                mt={5}
                py={5}
                px={8}
                borderTopWidth={1}
                borderColor="brand.light"
              >
                <Button onClick={handleUpdate} disabled={successful}>
                  {successful && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}{" "}
                  Mise à jour
                </Button>
            </Box>
          </TabPanel>
          </TabPanels>
        </Tabs>

        {/* <Actions /> */}
      </Box>
      <Modal
        title="Notification"
        footer={null}
        open={isModalOpen}
        onOk={handleOk}
        okText="Confirmer"
        cancelText="Annuler"
        onCancel={handleCancel}
      >
        {message && (
          <div className="form-group">
            <div
              className={
                successful ? "alert alert-danger" : "alert alert-success"
              }
              role="alert"
            >
              {message}
            </div>
          </div>
        )}
      </Modal>

      <Modal
        title="Notification"
        footer={null}
        open={isModalOpen1}
        onOk={handleOk1}
        okText="Confirmer"
        cancelText="Annuler"
        onCancel={handleCancel1}
      >
        {message && (
          <div className="form-group">
            <div
              className={
                successful ? "alert alert-danger" : "alert alert-success"
              }
              role="alert"
            >
              {message}
            </div>
          </div>
        )}

        <Button
                  className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[10px] 3xl:text-[18px] text-center w-[40%]"
                  shape="RoundedBorder8"
                  onClick={handleOk1}
                  >
                 
                  Fermer
                </Button>
      </Modal>
    </>
  );
};

export default Content;
/* eslint-disable no-empty-pattern */
import { Space, Modal, Button } from 'antd';
import React, { useState } from 'react';
import { InfoCircleTwoTone   } from '@ant-design/icons';
import moment from "moment"


function showinfosReclamations(record) {
    
    Modal.info({
        title: 'Details de la reclamation',
        content: (
            <p style={{ marginTop: 10 }}>
                <p>Nom :  {record.user}</p> 
                <p>Montant :  {record.amount}</p>
                <p>Date :  {moment(record?.createdAt).format("DD-MM-YYYY HH:mm:ss")}</p>
                <p>Etat :  {record.status}</p>
               
            

                <br />
                {/* <DemandeProgression step={step} /> */}
            </p>
        )
    });
}
export default function ReclamationsActions(props) {
   
    const [] = useState(props?.record?.reclamations_approvement?.approvement)
    const [loading] = useState(false)
    if (loading) return <Button type="primary" shape="circle" loading />
    return (
        <Space size="small">
            <Button icon={<InfoCircleTwoTone color="#1890ff" size="25px" title="Details Reclamations" />} type="text" onClick={() => { showinfosReclamations(props?.record) }} />
            
        </Space>
    )

}

 
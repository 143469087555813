import axios from "axios";
import authHeader from "./auth-header2";

const createCaissier = (firstName,lastName,username,phone,CNI,email,adresse,created_by,img1,img2) => {
  const data = new FormData();
  data.append('firstName', firstName);
  data.append('lastName', lastName);
  data.append('username', username);
  data.append('phone', phone);
  data.append('CNI', CNI);
  data.append('email', email);
  data.append('adresse', adresse);
  data.append('created_by', created_by);
  data.append('img1', img1);
  data.append('img2', img2);


  const config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/caissier`,
    headers: authHeader(),
    data : data
  };
  return axios(config)
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    createCaissier
}
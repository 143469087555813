/* eslint-disable no-unused-vars */
import React from "react";
import { createSupCom } from "../../actions/supCommercial";
import { useDispatch } from "react-redux";
import Form from "react-validation/build/form";
import {useState} from 'react';
import { Column, Row, Button, Img, Text, Line, Input, List, Stack,} from "components";
import Sidebar from "components/Sidebar/Sidebar";
import { useNavigate,Navigate } from "react-router-dom";
import Dropdown from "components/Dropdown";
import { useSelector } from "react-redux";
import { Modal } from 'antd';

const AjoutSuperviseurComPage = () => {
  const navigate = useNavigate();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { isLoggedIn } = useSelector(state => state.auth);
  const created_by = currentUser.response.user.id
  const  [firstName, setfirstName]  = React.useState ( "" );
  const  [lastName, setlastName]  = React.useState ( "" );
  const  [username, setUsername]  = React.useState ( "" );
  const  [adresse, setAdresse]  = React.useState ( "" );
  const  [phone, setPhone]  = React.useState ( "" );
  const  [CNI, setCNI]  = React.useState ( "" );
  const  [email, setEmail]  = React.useState ( "" );
  const [error, setError] = useState(null);
  const [error1, setError1] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState();
  const [file1, setFile1] = useState();
  const [fileName, setFileName] = useState("");
  
  const saveFile = (e) => {
    const image = e.target.files[0]
        setFile(image);
        setFileName(e.target.files[0].name);
      };

  const saveFile1 = (e) => {
        const image = e.target.files[0]
            setFile1(image);
            setFileName(e.target.files[0].name);
          };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  function isValidPhone(phone) {
   return  /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{3})$/.test(phone);
}
 
  const [successful, setSuccessful] = useState(false);
  const dispatch = useDispatch();
  
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChangefirstName = (e) => {
    const firstName = e.target.value;
    setfirstName(firstName);
  };

  const onChangelastName = (e) => {
    const lastName = e.target.value;
    setlastName(lastName);
  };
  
  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeAdresse = (e) => {
    const adresse = e.target.value;
    setAdresse(adresse);
  };

  const onChangePhone = (e) => { 
    if (!isValidPhone(e.target.value)) {
   setError1('Ce numero de telephone est invalide');
 } else {
   setError1(null);
 }
   const phone = e.target.value;
   setPhone(phone);
 };

  const onChangeCNI = (e) => {
    const CNI = e.target.value;
    setCNI(CNI);
  };

  const onChangeEmail = (e) => {
    if (!isValidEmail(e.target.value)) {
      setError('Cet email est invalide');
    } else {
      setError(null);
    }
    const email = e.target.value;
    setEmail(email);
  };

  const handleSubmit =  (e) => {
    e.preventDefault();
    dispatch(createSupCom(firstName,lastName,username,phone,CNI,email,adresse,created_by,file,file1))
      .then(() => {
        navigate("/listesupcom");
         //window.location.reload();
      })
      .catch(() => {
        setSuccessful(false);
      });
  };

  function handleNavigate28() {
    navigate("/listesupcom");
  }
  function handleNavigate29() {
    navigate("/dashboardsuperviseur");
  }

  const isEnabled = firstName.length > 0 && lastName.length > 0 && username.length > 0 && phone.length > 8 && CNI.length > 0 && email.length > 11 && adresse.length > 0 ;
  if (!isLoggedIn) {
    return <Navigate replace to="/login" />;
  } else { 
    return (
      <>
        <Column className="bg-bluegray_50 font-montserrat items-center justify-start mx-[auto] w-[100%]">
          <Row className=" justify-between w-[100%]">
            <Sidebar className="w-[20%]" onDashboardClick={handleNavigate29} />
            <Column className="bg-white_A700 items-center lg:py-[25px] xl:py-[32px] 2xl:py-[36px] 3xl:py-[43px] rounded-bl-[0] rounded-br-[0] rounded-tl-[12px] rounded-tr-[12px] shadow-bs2 w-[63%]">
              <Column className="items-center justify-start xl:mb-[115px] 2xl:mb-[129px] 3xl:mb-[155px] lg:mb-[92px] w-[100%]">
                <Row className="items-start w-[92%]">
                  <Button
                    className="flex lg:h-[22px] xl:h-[28px] 2xl:h-[31px] 3xl:h-[37px] items-center justify-center my-[1px] rounded-radius50 lg:w-[21px] xl:w-[27px] 2xl:w-[30px] 3xl:w-[36px]"
                    size="smIcn"
                    variant="icbFillGray201"
                  >
                    <Img
                      src="images/img_arrowleft.svg"
                      className="flex items-center justify-center"
                      onClick={handleNavigate28}
                      alt="arrowleft"
                    />
                  </Button>
                  <Text
                    className="font-montserrat font-semibold lg:ml-[20px] xl:ml-[26px] 2xl:ml-[29px] 3xl:ml-[35px] lg:mt-[3px] 2xl:mt-[4px] xl:mt-[4px] 3xl:mt-[5px] text-black_900 w-[auto]"
                    as="h5"
                    variant="h5"
                  >
                    Ajouter un superviseur
                  </Text>
                </Row>
                <Line className="bg-black_900_19 h-[1px] lg:mt-[25px] xl:mt-[32px] 2xl:mt-[36px] 3xl:mt-[43px] w-[100%]" />
                <Column className="justify-start lg:mt-[31px] xl:mt-[39px] 2xl:mt-[44px] 3xl:mt-[53px] w-[93%]">
                <Form>
                    <Text
                        className="text-black_900_cc w-[auto]"
                        as="h6"
                        variant="h6"
                      >  <span style={{color:"red"}}>*</span>
                        Nom du superviseur
                      </Text>
                      <Input
                        className="placeholder:text-black_900_b2 GroupSeventeen"
                        wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                        name="GroupSeventeen"
                        placeholder = "Nom de famille"
                        value={firstName}
                        onChange={onChangefirstName}
                      ></Input>
                    <Text
                        className="text-black_900_cc w-[auto]"
                        as="h6"
                        variant="h6"
                      >  <span style={{color:"red"}}>*</span>
                        Prenom du superviseur
                      </Text>
                      <Input
                        className="placeholder:text-black_900_b2 GroupSeventeen"
                        wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                        name="GroupSeventeen"
                        placeholder = "Prenom"
                        value={lastName}
                        onChange={onChangelastName}
                      ></Input>
                      <Text
                        className="text-black_900_cc w-[auto]"
                        as="h6"
                        variant="h6"
                      >  <span style={{color:"red"}}>*</span>
                        Nom d'utilisateur
                      </Text>
                      <Input
                        className="placeholder:text-black_900_b2 GroupSeventeen"
                        wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                        name="GroupSeventeen"
                        placeholder = "Nom d'utilisateur"
                        value={username}
                        onChange={onChangeUsername}
                      ></Input>
                      <Text className="labelCounter" as="h6" variant="h6">  <span style={{color:"red"}}>*</span>
                        Numero du superviseur
                      </Text>
                      <Input
                        className="placeholder:text-black_900_b2 GroupSeventeen"
                        wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                        name="GroupFourteen"
                        placeholder = "7X XXX XX XX"
                        value={phone}
                        onChange={onChangePhone}
                      /> 
                      {error1 && <h2 style={{color: 'red'}}>{error1}</h2>}
                      <Text className="labelCounter" as="h6" variant="h6">  <span style={{color:"red"}}>*</span>
                        Adresse du superviseur
                      </Text>
                      <Input
                        className="placeholder:text-black_900_b2 GroupSeventeen"
                        wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                        name="GroupSixteen"
                        placeholder = "Adresse"
                        value={adresse}
                        onChange={onChangeAdresse}
                      ></Input>
                      <Text className="labelCounter" as="h6" variant="h6">  <span style={{color:"red"}}>*</span>
                        Piéce d'identité
                      </Text>
                      <Input
                        className="placeholder:text-black_900_b2 GroupSeventeen"
                        wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                        name="GroupFourteen"
                        placeholder = "Piece justificative"
                        value={CNI}
                        onChange={onChangeCNI}
                      ></Input> 
                       <Text className="labelCounter" as="h6" variant="h6">
                      <span style={{ color: "red" }}>*</span> Photo piéce d'identité  (Recto et Verso)
                    </Text>
                    <br/>
                    <input style={{ color: "red" }} type="file" onChange={saveFile} />

                    <input style={{ color: "red", marginTop:"10px" }} type="file" onChange={saveFile1} />
                      
                      <Text className="labelCounter" as="h6" variant="h6"> <span style={{color:"red"}}>*</span>
                        Email
                      </Text>
                      <Input
                        className="placeholder:text-black_900_b2 GroupSeventeen"
                        wrapClassName="2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] w-[100%] xl:mt-[9px]"
                        name="GroupFourteen"
                        placeholder = "user@gmail.com"
                        value={email}
                        onChange={onChangeEmail}
                      />
                      {error && <h2 style={{color: 'red'}}>{error}</h2>}
                      </Form>
                      <Button
                        className="common-pointer font-semibold lg:mt-[43px] xl:mt-[54px] 2xl:mt-[60px] 3xl:mt-[73px] lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px] text-center w-[100%]"
                        shape="RoundedBorder8"
                        size="md"
                        variant="FillIndigoA700" 
                        disabled={!isEnabled}
                         onClick={showModal}
                      >
                        Ajouter le superviseur
                      </Button> 
                      <Modal  title="Informations sur le superviseur commercial que vous souhaitez créer" footer={null} open={isModalOpen} onOk={handleOk} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel}>
        <Text className="Numrodubnf" as="h5" variant="h5">
        Prenom du superviseur :
        </Text>
        <Text className="Numrodubnf" >
        {firstName}
        </Text>
        <br/>
        <br/>
        <Text className="Numrodubnf" as="h5" variant="h5">
        Nom du superviseur :
        </Text>
        <b>  <Text className="Numrodubnf" >
        {lastName}
        </Text> </b>
        <br/>
        <br/>
        <Text className="Numrodubnf" as="h5" variant="h5">
        Nom d'utillisateur :
        </Text>
        <b> <Text className="Numrodubnf"  >
        {username}
        </Text> </b>
        <br/>
        <br/>
        <Text className="Numrodubnf" as="h5" variant="h5">
        Numero du superviseur commercial :
        </Text>
        <b>   <Text className="Numrodubnf">
        {phone}
        </Text> </b>
        <br/>
        <br/>
        <Text className="Numrodubnf" as="h5" variant="h5">
        Adresse du superviseur commercial  :
        </Text>
        <b>  <Text className="Numrodubnf"  >
        {adresse}
        </Text> </b>
        <br/>
        <br/>
        <Text className="Numrodubnf" as="h5" variant="h5">
        Piece d'identité :
        </Text>
        <b> <Text className="Numrodubnf" >
        {CNI}
        </Text> </b>
        <br/>
        <br/>
        <Text className="email" as="h5" variant="h5">
        Email:
        </Text>
        <b>  <Text className="email">
        {email}
        </Text> </b>
        <br/>
        <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
          <Button
            className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
            shape="RoundedBorder8"
            onClick={handleOk}
          >
            Annuler
          </Button>
          <Button
            className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
            shape="RoundedBorder8" 
            variant="FillIndigoA700"
            onClick={handleSubmit}
            
            >
            {successful && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            Confirmer
          </Button>
        </Row>
      </Modal>
                  
                </Column>
              </Column>
            </Column>
            
            <Column className="items-center w-[15%]">
              <Row className="bg-white_A700 items-center justify-between lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius16 w-[100%]">
              <Dropdown />
              <Text className="rowsettings" variant="body1">
              <strong>{currentUser ? (currentUser.response.user.username):null}</strong>
              </Text>
                <Img
                  src="images/img_ellipse1.png"
                  className="lg:h-[26px] xl:h-[33px] 2xl:h-[37px] 3xl:h-[44px] rounded-radius50 lg:w-[25px] xl:w-[32px] 2xl:w-[36px] 3xl:w-[43px]"
                  alt="EllipseOne"
                />
              </Row>
              <List
                className="gap-[0] min-h-[auto] xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] w-[100%]"
                orientation="vertical"
              >
                <Column className="listfluxdetransac">
                  <Column className="justify-start mt-[2px] w-[94%]">
                    <Text
                      className="font-medium text-black_900 w-[auto]"
                      variant="body2"
                    >
                      Flux de transactions
                    </Text>
                    <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                      <Img
                        src="images/img_vector2.png"
                        className="VectorTwo"
                        alt="VectorTwo"
                      />
                      <Img
                        src="images/img_vector1.svg"
                        className="VectorOne"
                        alt="VectorOne"
                      />
                    </Stack>
                    <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        lun
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mar
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mer
                      </Text>
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        jeu
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        ven
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        sam
                      </Text>
                      <Text
                        className="text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        dim
                      </Text>
                    </Row>
                  </Column>
                </Column>
                <Column className="listfluxdetransac">
                  <Column className="justify-start mt-[2px] w-[94%]">
                    <Text
                      className="font-medium text-black_900 w-[auto]"
                      variant="body2"
                    >
                      Flux de transactions
                    </Text>
                    <Stack className="3xl:h-[111px] lg:h-[66px] xl:h-[83px] 2xl:h-[93px] lg:mt-[23px] xl:mt-[28px] 2xl:mt-[32px] 3xl:mt-[38px] w-[100%]">
                      <Img
                        src="images/img_vector2.png"
                        className="VectorThree"
                        alt="VectorThree"
                      />
                      <Img
                        src="images/img_vector1.svg"
                        className="VectorOne"
                        alt="VectorFour"
                      />
                    </Stack>
                    <Row className="items-start justify-between lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] w-[100%]">
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        lun
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mar
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        mer
                      </Text>
                      <Text
                        className="mt-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        jeu
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        ven
                      </Text>
                      <Text
                        className="my-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        sam
                      </Text>
                      <Text
                        className="mb-[1px] text-bluegray_100 w-[auto]"
                        as="h1"
                        variant="h1"
                      >
                        dim
                      </Text>
                    </Row>
                  </Column>
                </Column>
              </List>
              <Column className="bg-white_A700 justify-end lg:mt-[16px] xl:mt-[20px] 2xl:mt-[22px] 3xl:mt-[27px] lg:p-[16px] xl:p-[20px] 2xl:p-[22px] 3xl:p-[27px] rounded-radius16 w-[100%]">
                <Text className="columnfluxdetransac_two" variant="body2">
                  Flux de transactions
                </Text>
                <Column className="items-center justify-start lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] mx-[auto] xl:p-[11px] 2xl:p-[12px] 3xl:p-[14px] lg:p-[8px] w-[89%]">
                  <Stack className="2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] w-[85%]">
                    <div className="absolute bg-blue_A700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                    <div className="absolute bg-cyan_400 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                    <div className="absolute bg-yellow_700 2xl:h-[109px] 3xl:h-[131px] lg:h-[78px] xl:h-[97px] rounded-radius57 w-[100%]"></div>
                    <Column className="absolute bottom-[19%] justify-start right-[10%] w-[73%]">
                      <Row className="items-start justify-between w-[100%]">
                        <Text className="Thirty" variant="body2">
                          30%
                        </Text>
                        <Text className="Forty" variant="body2">
                          40%
                        </Text>
                      </Row>
                      <Text className="columnthirty" variant="body2">
                        30%
                      </Text>
                    </Column>
                  </Stack>
                </Column>
              </Column>
              <Button
                className="2xl:mt-[85px] 3xl:mt-[102px] flex items-center justify-center lg:mt-[60px] text-center w-[92%] xl:mt-[76px]"
                leftIcon={
                  <Img
                    src="images/img_camera_24X24.svg"
                    className="text-center lg:w-[16px] lg:h-[17px] lg:mr-[8px] xl:w-[20px] xl:h-[21px] xl:mr-[10px] 2xl:w-[22px] 2xl:h-[23px] 2xl:mr-[11px] 3xl:w-[27px] 3xl:h-[28px] 3xl:mr-[13px]"
                    alt="camera"
                  />
                }
                shape="RoundedBorder8"
                size="md"
                 variant="FillIndigoA700"
              >
                <div className="bg-transparent font-bold xl:text-[10px] 2xl:text-[11px] 3xl:text-[13px] lg:text-[8px]">
                  Réapprovisionner
                </div>
              </Button>
            </Column>
  
          </Row>
        </Column>
      </>
    );
  }
  
};

export default AjoutSuperviseurComPage;
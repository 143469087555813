/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import { Space, Modal } from 'antd';
import React, { useState } from 'react';
import { StopTwoTone,CheckCircleTwoTone,TeamOutlined,InfoCircleTwoTone  } from '@ant-design/icons';
import { useNavigate } from "react-router-dom"; 
import { useDispatch,useSelector } from 'react-redux';
import {block} from "../../actions/blockuser";
import { deblock } from "../../actions/deblock";
import OTPInput from "otp-input-react";
import { Row,Button,Column,Img } from 'components';
import { verifyPhoneOtp } from "../../actions/user";

// // ici on a popup qui affiche tous les details et fichiers joints par le client
function showinfosMaster(record) {
    
    Modal.info({
        title: 'Details du Master',
        content: (
            <p style={{ marginTop: 10 }}>
               <p>Prenom :  {record.firstName}</p>
                <p>Nom :  {record.lastName}</p>
                <p>Username :  {record.username}</p>
                <p>Adresse:  {record.adresse}</p>
                <p>Numero :  {record.phone}</p>
                <p>Email :  {record.email}</p>
                <p>CNI :  {record.cni}</p>
                <br />
                {/* <DemandeProgression step={step} /> */}
            </p>
        )
    });
}
export default function MasterActions(props) {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [successful, setSuccessful] = useState(false);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const  [otp, setOtp]  = React.useState ("");
  const response = JSON.parse(localStorage.getItem('user'));
  const { message } = useSelector(state => state.message);
  
  const generate = () => {
        setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  const handleOk1 = () => {
    setIsModalOpen1(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const handleOk2 = () => {
    setIsModalOpen2(false);
  };
  const handlesubmit1 = () => {
    window.location.reload();
  };
  const state = props?.record
  const userId = currentUser.response.user.id
  const id = state.id;

  const handleSubmit =  (e) => {
  e.preventDefault();
  setSuccessful(true); 
   dispatch(verifyPhoneOtp(userId, otp))
    .then(() => {
      setIsModalOpen(false);
      setIsModalOpen1(true);
      dispatch(block(id,userId))
    })
    .catch(() => {
      setSuccessful(false);
    });
};

const handleSubmit0 = (e) => {
  e.preventDefault();
  setSuccessful(true); 
   dispatch(verifyPhoneOtp(userId, otp))
    .then(() => {
      setIsModalOpen(false);
      setIsModalOpen2(true);
      dispatch(deblock(id,userId))
    })
    .catch(() => {
      setSuccessful(false);
    });
};
    const navigate = useNavigate();

    // function updateMaster(record){
    //     navigate(
    //         '/modifiermaster',
    //         {
    //           state: {
    //             id:record?.id,
    //             firstName:record?.firstName,
    //             lastName:record?.lastName,
    //             username:record?.username,
    //             adresse: record?.adresse,
    //             phone: record?.phone,
    //             cni: record?.cni,
    //             email: record?.email,
    //           }
    //         }
    //       ) 
    // }

    function liste(record){
      navigate(
          '/listeusers',
          {
            state: {id:record?.id }
          }
        
        ) 
  }

    const [] = useState(props?.record?.banker_approvement?.approvement)
    const [loading, ] = useState(false)
    if (loading) return <Button type="primary" shape="circle" loading />  
    const blocked = props?.record?.block
    if (blocked === true) 
    return ( 
    <Space size="small">
    <button onClick={() => { showinfosMaster(props?.record) }}> <InfoCircleTwoTone color="#1890ff" size="25px" title="Details Master" /></button>
    <button onClick={generate} > <CheckCircleTwoTone twoToneColor="#52c41a" title="Debloquer" /></button>
    <button onClick={() => { liste(props?.record) }}> <TeamOutlined twoToneColor="#eb2f96" title="Utilisateurs"  /></button>
  
    
    <Modal  title="Verification code OTP" footer={null} open={isModalOpen} onOk={handleOk} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel}>
   {message && (
           <div className="form-group">
             <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
               {message}
             </div>
           </div>
         )}
            <div class=" p-2 text-center">
          <Img
              src="images/coolupay1.png"
              className="lg:h-[80px] xl:h-[80px] 2xl:h-[42px] 3xl:h-[51px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[85%]"
              alt="logoCoolupay"
            />
            <h4>Veuillez entrer le code OTP pour debloquer le master</h4>
            <br/>
            <div className="absolute content-center items-center  right-[-10] top-[20] ">
          <OTPInput
           inputStyles={{width: "20%",
                         height: "30px",
                               textAlign: "center",
                              fontSize: "14px",
                               fontWeight: "bold"}}
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={4}
              otpType="number" disabled={false}  />
              <div></div>
          </div>
          </div>
            <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
              <Button
                className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                shape="RoundedBorder8"
                onClick={handleOk}
              >
                Annuler
              </Button>
              <Button
                className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                shape="RoundedBorder8" variant="FillIndigoA700"
                onClick={handleSubmit0}
                disabled={successful}>
                {successful && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Confirmer
              </Button>
            </Row>
    </Modal>
    <Modal footer={null} open={isModalOpen2} onOk={handleOk2} onCancel={handleCancel2} closable={false}>
           <div className="m-[auto] max-h-[97vh] items-center  w-[-100%]">
          <Column className="bg-gray_102 items-center justify-start lg:p-[26px] xl:p-[32px] 2xl:p-[36px] 3xl:p-[439px] rounded-radius16 w-[100%]">
                   <Img
                     src="images/img_close.svg"
                     className="common-pointer test_1200x630waTwo"
                     onClick={handlesubmit1}
                     alt="close"
                   />
                     
                      <h4 className="columnclose2 alert alert-success" as="h8" variant="h8"> Vous avez débloqué ce master </h4>
                   <Img
                    src="images/valide.png"
                     className="sandclockOne"
                     alt="sandclockOne"
                     width= "20%"
                   />
                 </Column>
      </div>
       </Modal>
    </Space> )
        else
    return (
        <Space size="small">
            <button onClick={() => { showinfosMaster(props?.record) }} > <InfoCircleTwoTone color="#1890ff" size="25px" title="Details Master" /></button>
            <button onClick={generate} > <StopTwoTone twoToneColor="#eb2f96" title="Bloquer" /></button>
            <button  onClick={() => { liste(props?.record) }}> <TeamOutlined twoToneColor="#52c41a" title="Utilisateurs" /></button>
  
            <Modal  title="Verification code OTP" footer={null} open={isModalOpen} onOk={handleOk} okText="Confirmer" cancelText="Annuler" onCancel={handleCancel}>
   {message && (
           <div className="form-group">
             <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
               {message}
             </div>
           </div>
         )}
            <div class=" p-2 text-center">
          <Img
              src="images/coolupay1.png"
              className="lg:h-[80px] xl:h-[80px] 2xl:h-[42px] 3xl:h-[51px] lg:ml-[27px] xl:ml-[33px] 2xl:ml-[38px] 3xl:ml-[45px] lg:mt-[35px] xl:mt-[44px] 2xl:mt-[49px] 3xl:mt-[59px] w-[85%]"
              alt="logoCoolupay"
            />
            <h4>Veuillez entrer le code OTP pour bloquer le master</h4>
            <br/>
            <div className="absolute content-center items-center  right-[-10] top-[20] ">
          <OTPInput
           inputStyles={{width: "20%",
                         height: "30px",
                               textAlign: "center",
                              fontSize: "14px",
                               fontWeight: "bold"}}
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={4}
              otpType="number" disabled={false}  />
              <div></div>
          </div>
          </div>
            <Row className="items-center space-x-4 justify-between lg:mt-[45px] xl:mt-[56px] 2xl:mt-[63px] 3xl:mt-[76px] w-[60%]">
              <Button
                className="common-pointer font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                shape="RoundedBorder8"
                onClick={handleOk}
              >
                Annuler
              </Button>
              <Button
                className="font-semibold lg:text-[10px] xl:text-[13px] 2xl:text-[15px] 3xl:text-[18px] text-center w-[60%]"
                shape="RoundedBorder8" variant="FillIndigoA700"
                onClick={handleSubmit}
                disabled={successful}>
                {successful && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Confirmer
              </Button>
            </Row>
    </Modal> 
     <Modal footer={null} open={isModalOpen1} onOk={handleOk1} onCancel={handleCancel1} closable={false}>
           <div className="m-[auto] max-h-[97vh] items-center  w-[-100%]">
          <Column className="bg-gray_102 items-center justify-start lg:p-[26px] xl:p-[32px] 2xl:p-[36px] 3xl:p-[439px] rounded-radius16 w-[100%]">
                   <Img
                     src="images/img_close.svg"
                     className="common-pointer test_1200x630waTwo"
                     onClick={handlesubmit1}
                     alt="close"
                   />
                     
                      <h4 className="columnclose2 alert alert-success" as="h8" variant="h8"> Vous avez bloqué ce master </h4>
                   <Img
                    src="images/invalide.webp"
                     className="sandclockOne"
                     alt="sandclockOne"
                     width= "20%"
                   />
                 </Column>
      </div>
       </Modal>
        </Space>
    )

}

 
import { VALIDE_SUCCESS, VALIDE_FAIL, SET_MESSAGE,} from "./types";
import update from "../services/update";

export const updateUser = (id,firstName,lastName,username,phone,cni,email,adresse,created_by) => (dispatch) => {
    return update.updateUser(id,firstName,lastName,username,phone,cni,email,adresse,created_by).then(
      (response) => {
        dispatch({
          type: VALIDE_SUCCESS,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: VALIDE_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    );
  };